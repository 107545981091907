class Menu {
  constructor() {
    let $menu = $('.js-menu');
    $menu.on('click', (e) => {
      let $target = $(e.currentTarget);
      let $parent = $target.closest('.js-menu-parent');
      $parent.siblings().removeClass('is-active');

      if ($parent.hasClass('is-active')) {
        $parent.removeClass('is-active');
        $('#gray-layer').removeClass('is-active');
      } else {
        $parent.addClass('is-active');
        $('#gray-layer').addClass('is-active');
      }
    });

    $('.js-menu-close').on('click', () => {
      $('.js-menu-parent,#gray-layer').removeClass('is-active')
    });

    $('.js-menu-switcn').on('click', (e) => {
      let $target = $(e.currentTarget);
      if ($target.hasClass('is-active')) {
        $target.removeClass('is-active');
        $('#gray-layer').removeClass('is-active');
      } else {
        $target.addClass('is-active');
        $('#gray-layer').addClass('is-active');
      }
    })
  }
}

export default Menu;
