class Scroll {
  constructor() {
    this.init();
    jQuery(window).on('load', () => {
      if (location.hash != '') {
        this.move(location.hash);
      }
    });
  }

  init() {
    jQuery('.js-scroll').on('click', (e) => {
      let target = jQuery(e.currentTarget).attr('href');
      if (target == "#") {
        target = 'html';
      }
      this.move(jQuery(target));
      return false;
    });
  }

  move(target) {
    jQuery("html,body").animate({
      scrollTop: jQuery(target).offset().top - 100
    }, 500);
  }
}

export default Scroll;