  // import Acd from './Acd';
  import Menu from './Menu';
  import Scroll from './Scroll';
  // import Tab from './Tab';

  $(function () {
    $('.js-slider').slick({
      autoplay: true,
      autoplaySpeed: 5000,
      dots: false,
      slidesToShow: 3,
      responsive: [{
        breakpoint: 768, //767px以下のサイズに適用
        settings: {
          slidesToShow: 1
        }
      }]
    });
    $('.js-slider02').slick({
      autoplay: false,
      autoplaySpeed: 5000,
      dots: false,
      slidesToShow: 4,
      responsive: [{
        breakpoint: 768, //767px以下のサイズに適用
        settings: {
          slidesToShow: 1
        }
      }]
    });
    $('.js-item').slick({
      autoplay: true,
      autoplaySpeed: 5000,
      dots: true,
      arrows: false,
      slidesToShow: 1,
    });

    new Menu();
    new Scroll();

    //検索
    $('.js-search-submit').on('click', () => {
      var erea = $('.js-search-list').val()
      if (erea != '') {
        var data = erea.split(':');
        $('#' + data[0]).fadeIn(500).siblings('.js-area-list').hide(0);
        $('.js-erea-name').text(data[1]);
      } else {
        $('.js-area-list').hide(0);
        $('.js-erea-name').empty();
      }
      return false;
    })

    $('.js-tab-switch').on('click', (e) => {
      let switchItem = $(e.currentTarget);
      switchItem.addClass('is-active').siblings().removeClass('is-active');
      let target = switchItem.find('a').attr('href');
      $(target).addClass('is-active').siblings().removeClass('is-active');
      return false;
    })

    if (navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/)) {
      $(window).on("load", function () {
        $(".table-wrapper").mCustomScrollbar({
          axis: "x" // horizontal scrollbar
        });
      });
    }

    $('.js-acd-switch').on('click', (e) => {
      let $target = $(e.currentTarget);
      if ($target.hasClass('is-active')) {
        $target.removeClass('is-active');

      } else {
        $target.addClass('is-active');
      }
    })
  });
